import { useEffect } from 'react'

import { NextPage } from 'next'
import { useRouter } from 'next/router'
import { useSnackbar } from 'notistack'

import ResearchLoginForm from '~/components/ResearchOnly/ResearchLoginForm'
import useTrackScreenView from '~/hooks/analytics/useTrackScreenView'
import { useTranslationForNamespace } from '~/hooks/useTranslationForNamespace'
import { withApollo } from '~/lib/withApollo'

const LoginPage: NextPage = () => {
  useTrackScreenView('pre_auth', 'auth')
  const t = useTranslationForNamespace('login')
  const { query } = useRouter()
  const { enqueueSnackbar } = useSnackbar()

  useEffect(() => {
    if (query?.source === 'logout') {
      enqueueSnackbar(t('loggedOut'), {
        variant: 'success',
        preventDuplicate: true,
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'center',
        },
      })
    }
  }, [enqueueSnackbar, query, t, query?.source])

  return <ResearchLoginForm />
}

export default withApollo({ ssr: true })(LoginPage)
